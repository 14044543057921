<footer class="footer-area footer-area-v3">
	<div class="container">
		<div class="footer-area-internal border-bottom-purple">
			<div class="row">
				<!-- Single Footer Widget -->
				<div class="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
					<div class="single-footer-widget footer-text-widget">
						<h5 class="widget-title">{{ 'FOOTER.aboutTitle' | translatePipe }}</h5>
						<p>{{ 'FOOTER.aboutTxt' | translatePipe }}</p>
					</div>
				</div>
				<!-- Single Footer Widget -->
				<div class="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
					<div class="single-footer-widget">
						<h5 class="widget-title">{{ 'FOOTER.services' | translatePipe }}</h5>
						<div class="footer-widget-menu">
							<ul>
								<li><a href="https://myconextion.com/">{{ 'FOOTER.insurances' | translatePipe }}</a></li>
								<li><a href="https://blog.myconextion.com/">{{ 'FOOTER.blog' | translatePipe }}</a></li>
							</ul>
						</div>
					</div> 
				</div>
				<!-- Single Footer Widget -->
				<div class="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.7s">
					<div class="single-footer-widget follow-on-widget">
						<h5 class="widget-title">{{ 'FOOTER.followUs' | translatePipe }}</h5>
						<div class="footer-social-links">
							<ul>
								<li><a href="https://www.facebook.com/myconextion/"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href="https://www.instagram.com/myconextion/"><i class="fab fa-instagram"></i></a></li>
								<li><a href="https://www.linkedin.com/company/my-conextion/"><i class="fab fa-linkedin"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
	<div class="footer-copyright-area wow fadeInDown" data-wow-delay="0.8s">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-6 col-sm-4">
					<div class="footer-logo">
						<img src="assets/img/logo-colored.png" alt="My Conextion">
					</div>
				</div>
				<div class="col-md-6 col-sm-8">
					<div class="footer-copyright">
						<p>© 2023 <a href="https://www.think-in.co/" target="_blank" rel="norel noopener noreferrer">Think In.</a> All Rights Reserved</p>
					</div>
				</div>
			</div>
		</div> 
	</div> 
</footer>
<a href="javascript:void(0)" [ngClass]="windowScrolled ? 'd-inline-block':''" (click)="scrollToTop()" class="scroll-to-target scroll-to-top bg-burning-orange"><i class="fa fa-angle-up"></i></a>