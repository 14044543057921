// author: Alejandro Bermúdez Restrepo
// company: Think In
// date: 25/05/2023
// tslint:disable: max-line-length
// tslint:disable: no-unused-expression
// import
import { Pipe, PipeTransform } from '@angular/core';
// translate
import { LanguageService } from 'src/app/shared/services/language.service';
// pipe
@Pipe({
  name: 'translatePipe'
})
// class
export class TranslatePipe implements PipeTransform {
  // variables
  translateString: any;
  // constructor
  constructor(
    public langSv: LanguageService,
  ) {}
  // transform
  transform(value: string): any {
    // translateString
    this.translateString = this.langSv.fastTranslation(value);
    // return data
    return this.translateString;
  }

}
