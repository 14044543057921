import { Component } from '@angular/core';
import { HelperService } from '../../helper/helper.service';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.css']
})
export class FooterMainComponent extends HelperService {

}
